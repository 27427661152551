.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}

.mdb-skin-custom .side-nav .collapsible li .collapsible-header {
  color: #424242;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
}
.mdb-skin-custom .side-nav .collapsible li .collapsible-body a {
  color: #424242;
  font-size: .78rem;
  height: 28px;
  line-height: 28px;
  background-color: rgba(95,93,93,.15);
}

.mdb-skin-custom .side-nav .search-form .md-form input {
  color: #424242!important;
  border-bottom: 1px solid rgba(153,153,153,.3);
}

.mdb-skin-custom .side-nav .search-form {
  border-top: 1px solid rgba(153,153,153,.3);
}
