body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-create {
  bottom: 45px;
  right: 24px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}

.mdb-skin-custom .side-nav .collapsible li .collapsible-header {
  color: #424242;
  transition: all .3s linear;
}
.mdb-skin-custom .side-nav .collapsible li .collapsible-body a {
  color: #424242;
  font-size: .78rem;
  height: 28px;
  line-height: 28px;
  background-color: rgba(95,93,93,.15);
}

.mdb-skin-custom .side-nav .search-form .md-form input {
  color: #424242!important;
  border-bottom: 1px solid rgba(153,153,153,.3);
}

.mdb-skin-custom .side-nav .search-form {
  border-top: 1px solid rgba(153,153,153,.3);
}

#login .view {
  background-image: url('/images/dykker-istock.jpg');
  background-size: cover;
  height: 100vh;
}

#login h6 {
  line-height: 1.7;
}

#login .form-check {
  margin-bottom: 0!important;
}
#login .md-form label {
  color: #ffffff;
}

#login .form-control {
  color: #000;
}

.pointer {
  cursor: pointer; }

#profile-v1 .cascading-admin-card .admin-up .fa {
    margin-right: 1rem;
}

#profile-v1 .user-card .admin-up .data span {
    font-size: 15px;
}
#profile-v1 .profile-card p, .user-card .about-text h4 {
    font-weight: 300;
}

.classic-admin-card .card-body {
    color: #fff;
    margin-bottom: 0;
    padding: .9rem;
}

.classic-admin-card .card-body p {
    font-size: 13px;
    opacity: 0.7;
    margin-bottom: 0px;
}

.classic-admin-card .card-body h4 {
    margin-top: 10px;
}

.cascading-admin-card .admin-up .fa {
    padding: 1.7rem;
    font-size: 2rem;
    color: #fff;
    text-align: left;
    border-radius: 3px;
}

.cascading-admin-card .admin-up .fa, .z-depth-2 {
    box-shadow: 0 8px 17px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
}

.cascading-admin-card .admin-up .data {
    float: right;
    margin-top: 2rem;
    text-align: right;
}
.cascading-admin-card .admin-up {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -20px;
}

